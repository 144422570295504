<template>
  <div class="animated fadeIn">
    <div v-if="loading" class="row justify-content-center">
      <div class="col mt-3 mb-3">
        <animation />
      </div>
    </div>

    <div v-else>
      <div v-if="stockAdjustment" class="row justify-content-start mb-3">
        <div class="col-auto">
          <h4 class="ml-md-0 ml-3">Documento {{stockAdjustment.number}}</h4>
        </div>
      </div>

      <div v-if="!stockAdjustment || !stockAdjustmentItems || !stockAdjustmentItems.length" class="mb-3 mt-3">
        <div class="row bg-light mx-0 py-3">
          <div class="col text-center">
            Nenhum Item adicionado
          </div>
        </div>
      </div>

      <div v-else class="mb-3 mt-3">
        <div v-for="(sai, index) in stockAdjustmentItems" class="row py-3 border mx-0" :key="index"
          :class="{ 'bg-light': index % 2 != 0 }">
          <div class="col-xl-2 col-md-3 col-3">
            <div class="row align-items-center">
              <div class="col-auto pr-0 mr-n3">
                <b-form-checkbox v-model="sai.confirm" switch size="lg"></b-form-checkbox>
              </div>
              <div class="col-auto pl-3 pt-3">
                <label>Confirmar</label>
              </div>
            </div>
          </div>

          <div class="col-xl-10 col-md-9 col-9">
            <div class="row">

              <div class="col-md-12 col-12">
                <span class="font-weight-bold">Item: </span>{{sai.item ? (getItemReduzidoFromRef(sai.item.ref) + ' - ' + sai.item.description) : ''}}
              </div>
              <div class="col-md-auto col-auto font-weight-bold">
                <span>Quantidade: </span>
                <span v-if="sai.generatedType === 'ENT'" class="text-success">
                  +{{Math.abs(sai.quantity - sai.currentQuantity)}}
                </span>
                <span v-else-if="sai.generatedType === 'SAI'" class="text-danger">
                  -{{Math.abs(sai.quantity - sai.currentQuantity)}}
                </span>
              </div>

            </div>
          </div>

          <div v-if="sai.confirm" class="col-md-12 mt-1">
            <div v-if="sai.generatedType === 'ENT'" class="row mr-0">

              <div class="col-md-6 col-sm-5 col-12 mb-sm-0 mb-3">
                <div class="row align-items-end">

                  <div class="col-sm-10 col-10 pr-0">
                    <label>Endereço:</label>
                    <model-list-select
                      :list="locations"
                      option-value="id"
                      option-text="name"
                      v-model="sai.location"
                      placeholder="Digite para pesquisar..."
                      @searchchange="searchLocation(index, $event)"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                  </div>

                  <div class="col-sm-2 col-2">
                    <div v-if="searchLocationInProcess">
                      <animation />
                    </div>
                    <b-button
                      v-else
                      variant="secondary"
                      @click.prevent="openLocationBarcodeInput(index)"
                    >
                      <i class="fa fa-barcode"></i>
                    </b-button>
                  </div>

                </div>

                <p v-if="!sai.location" class="font-weight-bold text-danger mt-1">Informe um endereço</p>
                <p v-else-if="sai.location.stockLocale && sai.location.stockLocale.id !== stockAdjustment.stockLocale.id"
                  class="font-weight-bold text-danger mt-1">
                  Endereço não pertence ao armazém do ajuste
                </p>
              </div>

              <div class="col-md-6 col-sm-7 col-12 pl-4">
                <div v-if="sai.item && sai.item.automaticItemLot">
                  Lote automático
                </div>
                <div v-else>
                  <b-form-group class="mb-2">
                    <b-form-radio-group
                      v-model="sai.createNewItemLot"
                    >
                      <b-form-radio :value="true">Criar novo lote</b-form-radio>
                      <b-form-radio :value="false">Usar existente</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>

                  <div class="row">
                    <div class="col-10 pr-0">
                      <model-list-select
                        :list="itemLots"
                        option-value="id"
                        option-text="lotNumber"
                        v-model="sai.itemLot"
                        placeholder="Selecione o Lote"
                        @searchchange="searchLot(index, $event)"
                        :filterPredicate="alwaysTrueFilterPredicate"
                        :isDisabled="sai.createNewItemLot"
                      />
                    </div>
                    <div v-if="loadingLots" class="col-2 mb-lg-0 mb-3">
                      <loading-animation />
                    </div>
                    <div v-else class="col-2 mb-lg-0 mb-3">
                      <b-button variant="secondary" @click.prevent="openLotBarcodeInput(index)" :disabled="sai.createNewItemLot">
                        <i class="fa fa-barcode"></i>
                      </b-button>
                    </div>
                  </div>

                  <p v-if="!sai.createNewItemLot && sai.itemLot && sai.itemLot.item && sai.itemLot.item.id !== sai.item.id"
                    class="font-weight-bold text-danger mt-1">
                    Lote não é compatível com o item
                  </p>
                </div>

              </div>

            </div>

            <div v-else-if="sai.generatedType === 'SAI'">
              <div class="card mb-0">
                <div class="card-header cursor-pointer" @click="toggleShowSuggestions(index)">
                  Sugestões
                  <i
                    :class="
                      'fa fa-lg ' +
                      (sai.showSuggestions ? 'fa-caret-down' : 'fa-caret-up') +
                      ' float-right mt-1 mr-0'
                    "
                  ></i>
                </div>
                <div v-if="sai.showSuggestions" class="card-body py-1">

                  <div v-if="sai.balances && sai.balances.length" class="mx-n3">
                    <div v-for="(balance, bIndex) in sai.balances" class="row py-3 border mx-md-0 mx-3" :key="bIndex"
                      :class="{ 'bg-light': bIndex % 2 != 0 }">

                      <div class="col-sm col-12">
                        <span class="font-weight-bold">Endereço: </span>
                        {{balance.location ? balance.location.name : ''}}
                      </div>

                      <div class="col-sm col-12">
                        <span class="font-weight-bold">Lote: </span>
                        {{balance.itemLot ? balance.itemLot.lotNumber : ''}}

                        <br>
                        <small class="text-muted">
                          <span class="font-weight-bold">Data: </span>
                          {{balance.formattedDate}}
                        </small>
                      </div>

                      <div class="col-sm col-12">
                        <span class="font-weight-bold">Quantidade: </span>
                        {{balance.quantity}}
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <h5><span class="font-weight-bold text-danger">OBS:</span> Os itens não confirmados terão que ser alocados/separados manualmente em seus pedidos. Uma vez executado o primeiro movimento o ajuste não pode mais ser alterado. Se você não quiser movimentar os demais itens você também pode excluí-los.</h5>

      <div class="row align-items-end">
        <div class="col">
          <button-back></button-back>
        </div>
        <div class="col-auto">
          <b-button type="button" variant="primary" class="btn-lg" :disabled="!areItemsValid" @click="apply">
            Aplicar
          </b-button>
        </div>
      </div>

      <barcode-input-modal
        id="location-barcode-input-modal"
        :attempts="locationBarcodeAttempts"
        :loading="loadingLocationBarcode"
        :ok="readLocationBarcode"
      ></barcode-input-modal>
      <barcode-input-modal
        id="lot-barcode-input-modal"
        :attempts="lotBarcodeAttempts"
        :loading="loadingLotBarcode"
        :ok="readLotBarcode"
      ></barcode-input-modal>
    </div>
  </div>
</template>

<script>
import ButtonBack from '@/components/forms/c-button-back'
import Animation from '@/components/loaders/animation'
import { httpClient } from '@/service'
import shared from '@/shared/shared';
import { ModelListSelect } from 'vue-search-select'
import BarcodeInputModal from "@/components/common/barcode-input-modal";
import { LoadingAnimation } from '@/components/loaders'
import PrepareSugggestionsTable from '@/components/tables/prepare-suggestions-table';

export default {
  name: 'auto-fill-revision',
  components: {
    ButtonBack,
    Animation,
    ModelListSelect,
    BarcodeInputModal,
    LoadingAnimation ,
    'prepare-suggestions-table': PrepareSugggestionsTable,
  },

  data() {
    return {
      loading: false,
      stockAdjustment: null,
      stockAdjustmentItems: [],
      searchLocationInProcess: false,
      locations: [],
      locationSearchTimeout: null,
      loadingLocationBarcode: false,
      locationBarcodeAttempts: 0,
      selectedItemIndex: -1,
      itemLots: [],
      loadingLots: false,
      lotSearchTimeout: null,
      loadingLotBarcode: false,
      lotBarcodeAttempts: 0,
    }
  },

  mounted() {
    this.findAdjustment();
  },

  computed: {
    areItemsValid() {
      if (!this.atLeastOneConfirmed) {
        return false;
      }

      for (let i = 0; i < this.stockAdjustmentItems.length; i++) {
        let sai = this.stockAdjustmentItems[i];

        if (sai.confirm) {
          if (sai.generatedType === 'ENT') {
            if (!sai.location) {
              return false;
            }

            if (!sai.createNewItemLot && sai.itemLot && sai.itemLot.item && sai.itemLot.item.id !== sai.item.id) {
              return false;
            }
          }

          if (sai.location && sai.location.stockLocale && sai.location.stockLocale.id !== this.stockAdjustment.stockLocale.id) {
            return false;
          }
        }
      }

      return true;
    },

    atLeastOneConfirmed() {
      for (let i = 0; i < this.stockAdjustmentItems.length; i++) {
        let sai = this.stockAdjustmentItems[i];

        if (sai.confirm) {
          return true;
        }
      }

      return false;
    }
  },

  methods: {
    findAdjustment() {
      this.loading = true;
      httpClient.get(`${process.env.VUE_APP_API_URL}stock-adjustments/${this.$route.params.id}`)
      .then(data => {
        this.loading = false;
        this.stockAdjustment = data.data.data;
        this.fillStockAdjustmentItemsAutomatically();
      }).catch(error => {
        this.loading = false;
        if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError("Houve um erro ao buscar o ajuste");
        }
      });
    },

    fillStockAdjustmentItemsAutomatically() {
      this.loading = true;
      return httpClient
      .get(`${process.env.VUE_APP_API_URL}stock-adjustments/items/auto-fill/${this.$route.params.id}`)
      .then((data) => {
        this.loading = false;
        this.stockAdjustmentItems = data.data.data;

        this.stockAdjustmentItems.forEach(sai => {
          if (sai.location && sai.location.id && !this.locations.find(l => l.id === sai.location.id)) {
            this.locations.push(sai.location);
          }

          if (sai.itemLot && sai.itemLot.id && !this.itemLots.find(l => l.id === sai.itemLot.id)) {
            this.itemLots.push(sai.itemLot);
          }
        });
      })
      .catch((error) => {
        this.loading = false;

        if (error.message) {
          this.$notify.error(error);
        }
      });
    },

    getItemReduzidoFromRef(ref) {
      return shared.getItemReduzidoFromRef(ref);
    },

    alwaysTrueFilterPredicate() {
      return true;
    },

    async searchLocation(index, searchText) {
      if (!this.searchLocationInProcess && !this.loading && searchText && searchText.length) {
        if (this.locationSearchTimeout) {
          clearTimeout(this.locationSearchTimeout);
        }

        let conditions = [
          {
            field: "name",
            conditionalOperator: "LIKE_START",
            value: searchText,
          },
          {
            field: "stockLocale.id",
            value: this.stockAdjustment.stockLocale.id,
          },
          {
            logicalOperator: "OR",
            conditions: [
              {
                field: "item.id",
                value: this.stockAdjustmentItems[index].item.id,
                joinType: "LEFT",
              },
              {
                field: "item.id",
                conditionalOperator: "IS_NULL",
                joinType: "LEFT",
              },
            ],
          }
        ];

        this.locationSearchTimeout = setTimeout(() => {
          this.searchLocationInProcess = true;

          httpClient
            .post(
              `${
                process.env.VUE_APP_API_URL
              }locations/condition?page=${0}&size=${10}`,
              {
                conditions: conditions,
              }
            )
            .then(({ data }) => {
              this.searchLocationInProcess = false;

              data.data.content.slice().reverse().forEach(location => {
                let index = this.locations.findIndex(l => l.id === location.id);

                if (index > -1) {
                  this.locations.splice(index, 1);
                }

                this.locations.unshift(location);
              });

              if (!this.locations.find(l => !l.id)) {
                this.locations.push({ id: null, name: "Nenhum" });
              }
            })
            .catch((error) => {
              this.searchLocationInProcess = false;
              if (error.message) {
                this.$notify.error(error);
              }
            });
        }, 500);
      }
    },

    openLocationBarcodeInput(index) {
      this.selectedItemIndex = index;
      this.$bvModal.show("location-barcode-input-modal");
    },

    readLocationBarcode(barcode) {
      if (!this.loadingLocationBarcode) {
        if (barcode && barcode.length) {
          this.loadingLocationBarcode = true;
          httpClient
            .get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
            .then((data) => {
              this.loadingLocationBarcode = false;

              if (data.data.data) {
                if (
                  data.data.data.item &&
                  data.data.data.item.id !== this.stockAdjustmentItems[this.selectedItemIndex].item.id
                ) {
                  this.$notify.textError(
                    "Endereço não é compatível com este item"
                  );
                } else {
                  this.locations.unshift(data.data.data);
                  this.stockAdjustmentItems[this.selectedItemIndex].location = data.data.data;
                  this.$bvModal.hide("location-barcode-input-modal");
                  this.$notify.success("Endereço encontrado");
                }
              } else {
                this.$notify.textError("Endereço não encontrado");
              }

              this.locationBarcodeAttempts++;
            })
            .catch((error) => {
              this.loadingLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error);
              } else {
                this.$notify.textError(
                  "Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente"
                );
              }

              this.locationBarcodeAttempts++;
            });
        } else {
          this.$notify.textError(
            "Código inválido. Por favor insira um código de barras válido"
          );
        }
      }

      this.locationBarcodeAttempts++;
    },

    searchLot (index, searchText) {
      if (!this.loadingLots && searchText && searchText.length) {
        if (this.lotSearchTimeout) {
          clearTimeout(this.lotSearchTimeout);
        }

        this.lotSearchTimeout = setTimeout(() => {
          this.loadingLots = true

          let conditions = [{
            field: 'item.id',
            conditionalOperator: 'EQUALS',
            value: this.stockAdjustmentItems[index].item.id
          }];

          if (searchText && searchText.length) {
            conditions.push({
              field: 'lotNumber',
              conditionalOperator: 'LIKE_START',
              value: searchText
            });
          }

          httpClient
          .post(`${process.env.VUE_APP_API_URL}item-lots/condition?page=${0}&size=${10}&sort=createdAt,asc`, {
            conditions: conditions
          })
          .then(({ data }) => {
            this.loadingLots = false;

            data.data.content.slice().reverse().forEach(itemLot => {
              let index = this.itemLots.findIndex(l => l.id === itemLot.id);

              if (index > -1) {
                this.itemLots.splice(index, 1);
              }

              this.itemLots.unshift(itemLot);
            });

            if (!this.itemLots.find(l => !l.id)) {
              this.itemLots.push({ id: null, lotNumber: "Nenhum" });
            }
          })
          .catch((error) => {
            if (error.message) {
              this.$notify.error(error)
            }

            this.loadingLots = false
          });
        }, 500);
      }
    },

    openLotBarcodeInput(index) {
      this.selectedItemIndex = index;
      this.$bvModal.show('lot-barcode-input-modal');
    },

    readLotBarcode(barcode) {
      if (!this.loadingLotBarcode) {
        if (barcode && barcode.length) {
          this.loadingLotBarcode = true;
          httpClient.get(`${process.env.VUE_APP_API_URL}item-lots/barcode/${barcode}`)
          .then(data => {
            this.loadingLotBarcode = false;

            if (data.data.data) {
              if (data.data.data.item.id !== this.stockAdjustmentItems[this.selectedItemIndex].item.id) {
                this.$notify.textError('Lote não é compatível com este item');
              } else {
                this.itemLots.unshift(data.data.data);
                this.stockAdjustmentItems[this.selectedItemIndex].itemLot = data.data.data;
                this.$bvModal.hide('lot-barcode-input-modal');
                this.$notify.success('Lote encontrado')
              }
            } else {
              this.$notify.textError('Lote não encontrado')
            }

            this.lotBarcodeAttempts++;
          }).catch(error => {
            this.loadingLotBarcode = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente')
            }

            this.lotBarcodeAttempts++;
          });
        } else {
          this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
        }
      }

      this.lotBarcodeAttempts++;
    },

    toggleShowSuggestions(index) {
      this.stockAdjustmentItems[index].showSuggestions = !this.stockAdjustmentItems[index].showSuggestions;
      this.reloadStockAdjustmentItems();
    },

    reloadStockAdjustmentItems() {
      let stockAdjustmentItems = this.stockAdjustmentItems;
      this.stockAdjustmentItems = [];
      this.stockAdjustmentItems = stockAdjustmentItems;
    },

    apply() {
      this.stockAdjustmentItems.forEach(sai => {
        if (sai.location && typeof sai.location === 'string') {
          sai.location = {
            id: sai.location
          }
        }

        if (sai.itemLot && typeof sai.itemLot === 'string') {
          sai.itemLot = {
            id: sai.itemLot
          }
        }
      });

      //Para enviar apenas os campos necessários, ver chamado 0000498517
      let body = this.stockAdjustmentItems.map(sai => {
        return {
          id: sai.id,
          confirm: sai.confirm,
          generatedType: sai.generatedType,
          createNewItemLot: sai.createNewItemLot,
          location: sai.location ? { id: sai.location.id } : undefined,
          itemLot: sai.itemLot ? { id: sai.itemLot.id } : undefined,
          item: {
            id: sai.item.id,
            ref: sai.item.ref,
            description: sai.item.description
          },
          balances: (sai.balances || []).map(b => {
            return {
              id: b.id,
              location: b.location ? { id: b.location.id } : undefined,
            }
          })
        }
      });

      this.loading = true;
      httpClient.post(
        `${process.env.VUE_APP_API_URL}stock-adjustments/items/auto-fill/${this.$route.params.id}/confirmation`, body
      ).then(data => {
        this.loading = false;
        this.$notify.warn('Processo adicionado à fila');
        this.$router.push('/orders/item-adjustment/all');
      }).catch(error => {
        this.loading = false;
        if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError("Houve um erro ao buscar o ajuste");
        }
      });
    }
  }
}
</script>
